import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import MEDIA from '../helpers/mediaTemplates';
import Layout from '../components/layout';
import Section from '../components/section';
import Img from 'gatsby-image';
import { Horizontal, Column } from '../components/section';
import Title from '../components/title';

const ReverseHorizontal = styled(Horizontal)`
  flex-direction: column-reverse;

  ${MEDIA.MIN_TABLET`
    flex-direction: row;
  `}
`;

const StyledImg = styled(Img)`
  margin-bottom: 1rem;

  ${MEDIA.MIN_TABLET`
    margin-bottom: 0;
  `}
`;

const About = ({ data }) => (
  <Layout>
    <Section>
      <Title pageTitle={data.aboutJson.title} />
      <ReverseHorizontal>
        <Column>
          <div
            dangerouslySetInnerHTML={{
              __html: data.aboutJson.content.childMarkdownRemark.html,
            }}
          />
        </Column>
        <Column>
          <StyledImg
            fluid={
              data.aboutJson.image
                ? data.aboutJson.image.childImageSharp.fluid
                : {}
            }
          />
        </Column>
      </ReverseHorizontal>
    </Section>
  </Layout>
);

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;

export const query = graphql`
  query AboutQuery {
    aboutJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      image {
        childImageSharp {
          fluid(maxHeight: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
